import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = {
  card: {
    // I think this is needed only for dragging contexts
    // backgroundColor: "white",
    // minWidth: 350,
    // minHeight: 200,
  },
  pos: {
    marginBottom: 12,
  },
};

class ProcessCard extends Component {
  render() {
    const { 
      classes, 
      Process,
      onCardAction,
    } = this.props;
    return (
      <div>
        <Card className={classes.card} /*style={{backgroundColor: (isDragging) && "gray" }}*/> 
          <CardActionArea
            onClick={onCardAction}>
            <CardContent /*style={{ opacity: (isDragging) && 0 }}*/>
              <Typography variant="h5" component="h2">
                {Process.Name}
              </Typography>
              {/* <Typography className={classes.pos} color="textSecondary"> */}
              {/*   {Process.Type} */}
              {/* </Typography> */}
            </CardContent>
          </CardActionArea>
          {/* <CardActions style={{ opacity: (isDragging) && 0 }}> */}
          {/*   <Button size="small" onClick={this.props.onDeleteProcess}>DELETE</Button> */}
          {/* </CardActions> */}
        </Card>
      </div>
    );
  }
}

ProcessCard.propTypes = {
  Process: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProcessCard);