import React from 'react';
import { withRouter } from 'react-router-dom'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardIcon from '@material-ui/icons/Dashboard';
import TableChartIcon from '@material-ui/icons/TableChart';
// import CreateIcon from '@material-ui/icons/Create';
// import PeopleIcon from '@material-ui/icons/People';
// import BusinessIcon from '@material-ui/icons/Business';

import MultiUseDialog from './MultiUseDialog';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class UiCore extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
    DrawerOpen: false,
    DialogDetails: {
      Open: false,
    }
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = event => {
    this.props.history.push("/logout");
    this.handleClose();
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleDrawer = (open) => () => {
    this.setState({
      DrawerOpen: open,
    });
  };

  handleApiError(err) {
    // console.log(err.response.status);
    // console.log(err.response.data.message);

  	if (typeof err.response === "undefined") {
  		err = {
  			response: {
  				status: 500,
  				data: {
					message: err.toString(),
  				},
  			},
  		};
  	}

    switch(err.response.status) {
      case 401:
      this.props.history.push("/login"); 
      break;
      case 500:
      this.handleShowDialog({
        Title: "There seems to be a problem...",
        BodyText: err.response.data.message,
      });
      break;
      default:
      break;
    }
  }

  handleShowDialog(details) {
    this.setState({
      DialogDetails: {
        Open: new Date(),
        IsConfirmation: details.IsConfirmation,
        RequireTextInput1: details.RequireTextInput1,
        Title: details.Title,
        BodyText: details.BodyText,
        BodyClassName: details.BodyClassName,
        TextInput1Label: details.TextInput1Label,
        TextInput1PlaceHolder: details.TextInput1PlaceHolder,
        TextInput1DefaultValue: details.TextInput1DefaultValue,
        CancelCallback: (details.CancelCallback) ? details.CancelCallback : () => this.props.history.push(details.CancelRedirectPath),
        CloseCallback: (details.CloseCallback) ? details.CloseCallback : () => this.props.history.push(details.CloseRedirectPath),
        ConfirmLabel: details.ConfirmLabel,
        ConfirmCallback: (details.ConfirmCallback) ? details.ConfirmCallback : () => this.props.history.push(details.ConfirmRedirectPath),
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.apiError !== "undefined" && this.props.apiError
      && prevProps.apiError !== this.props.apiError) {
      this.handleApiError(this.props.apiError);
    }
    if (typeof this.props.alert !== "undefined" && this.props.alert
      && prevProps.alert !== this.props.alert) {
      let { alert } = this.props;
      this.handleShowDialog(alert);
    }
    if (typeof this.props.confirmation !== "undefined" && this.props.confirmation
      && prevProps.confirmation !== this.props.confirmation) {
      let { confirmation } = this.props;
      this.handleShowDialog(Object.assign(confirmation, { IsConfirmation: true }));
    }
  }

  render() {
    const {
      classes,
      title,
      subtitle,
      onEditSubtitle,
    } = this.props;
    const { 
      auth,
      anchorEl,
      DrawerOpen,
      DialogDetails,
    } = this.state;
    const open = Boolean(anchorEl);

    const drawerList = (
      <div className={classes.list}>
        <Button component={Link} to="/dashboard">
          Logo
          {/* <img style={{width: 150, padding: 16}} src="/Paige-logo_272x250.png" alt="Dashboard" /> */}
        </Button>
        <Divider />
        <List>
          <ListItem button component={Link} to="/dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </List>

        <Divider />

        <List subheader={<ListSubheader component="div">Administration</ListSubheader>}>
          <ListItem button component={Link} to="/admin/processes">
            <ListItemIcon>
              <TableChartIcon />
            </ListItemIcon>
            <ListItemText primary="Processes" />
          </ListItem>
          {/* <ListItem button component={Link} to="/tenants"> */}
          {/*   <ListItemIcon> */}
          {/*     <BusinessIcon /> */}
          {/*   </ListItemIcon> */}
          {/*   <ListItemText primary="Tenants" /> */}
          {/* </ListItem> */}
        </List>
      </div>
    );

    let editableSubtitle = null;
    if (subtitle) {
      editableSubtitle = (
        <Typography variant="subtitle1" component="span" color="inherit"
          style={{marginLeft:24,display:"inline",cursor:"pointer"}}
          onClick={(e) => {if (onEditSubtitle) onEditSubtitle(e);}}>
          {subtitle}
        </Typography>
      );
    }

    return (
      <div className={classes.root}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
              onClick={this.toggleDrawer(true)}>
              {/* this.props.history.push("/") */}
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.grow}>
              {title}
              {editableSubtitle}
            </Typography>
            {auth && (
              <div>
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  {/* <MenuItem onClick={this.handleClose}>Profile</MenuItem> */}
                  {/* <MenuItem onClick={this.handleClose}>My account</MenuItem> */}
                  <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <SwipeableDrawer
          open={DrawerOpen}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {drawerList}
          </div>
        </SwipeableDrawer>

        <MultiUseDialog
          open={DialogDetails.Open}
          isConfirmation={DialogDetails.IsConfirmation}
          requireTextInput1={DialogDetails.RequireTextInput1}
          title={DialogDetails.Title}
          bodyText={DialogDetails.BodyText}
          bodyClassName={DialogDetails.BodyClassName}
          textInput1Label={DialogDetails.TextInput1Label}
          textInput1PlaceHolder={DialogDetails.TextInput1PlaceHolder}
          textInput1DefaultValue={DialogDetails.TextInput1DefaultValue}
          cancelCallback={DialogDetails.CancelCallback}
          closeCallback={DialogDetails.CloseCallback}
          confirmLabel={DialogDetails.ConfirmLabel}
          confirmCallback={DialogDetails.ConfirmCallback}
        />

      </div>
    );
  }
}

UiCore.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(UiCore));