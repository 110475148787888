import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import { withStyles } from '@material-ui/core/styles';

import UiCore from '../../Components/UiCore';
import ProcessCard from '../Components/ProcessCard';

// import { DropTarget } from 'react-dnd';

import AddIcon from '@material-ui/icons/Add';

import API from '../../Util/api';

// import queryString from 'query-string';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 4,
  },
  fab: {
    position: 'fixed',
    zIndex: 1,
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

// const gridTarget = {
//   drop(props, monitor, component) {
//     if (monitor.didDrop()) {
//       // If you want, you can check whether some nested
//       // target already handled drop
//       return;
//     } 
// 
//     // Obtain the dragged item
//     const processCard = monitor.getItem();
//     processCard.onDropProcess();
//     
//     // You can also do nothing and return a drop result,
//     // which will be available as monitor.getDropResult()
//     // in the drag source's endDrag() method
//     //return { moved: true };
//   },
// }

/**
 * Specifies which props to inject into your component.
 */
// function dropCollect(connect, monitor) {
//   return {
//     // Call this function inside render()
//     // to let React DnD handle the drag events:
//     connectDropTarget: connect.dropTarget(),
//     // You can ask the monitor about the current drag state:
//     //isOver: monitor.isOver(),
//     // isOverCurrent: monitor.isOver({ shallow: true }),
//     // canDrop: monitor.canDrop(),
//     // itemType: monitor.getItemType()
//   };
// }

class Processes extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Processes: [],
      Cursor: "",
      ActiveTeamKey: "",
      // PreMoveProcessesJson: "",
    }
  }

  Process = {
    Key: "",
    Name: "",
  }

  getNewProcess(name) {
    let newProcess = this.Process;
    newProcess.Name = name;
    return newProcess;
  }

  handleGetProcesses(teamKey) {
    API.get("/processes", 
      { 
        params: { 
          team: (teamKey) ? teamKey : undefined, 
          cursor: (this.state.Cursor) ? this.state.Cursor : undefined,
        }
      }
    )
      .then(resp => { 
        let processes = [...this.state.Processes];
        processes = processes.concat(resp.data.Data);
        this.setState({ Processes: processes, Cursor: resp.data.Cursor });
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handleConfirmAddProcess() {
    this.setState({
      Confirmation: {
        Title: "Create Process",
        RequireTextInput1: true,
        TextInput1Label: "Process Name",
        ConfirmLabel: "GO",
        ConfirmCallback: (textInput1) => this.handleAddProcess(textInput1),
      }
    });
  }

  handleAddProcess(name) {
    if (!name)
      return;
    let newProcess = this.getNewProcess(name);
    API.post("/processes", newProcess, 
      { 
        params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
        this.props.history.push("/admin/process/" + resp.data.Key);
      })
      .catch(err => this.setState({ApiError: err}));
  }

//   handleDropProcess(sourceProcess) {
//     if (!sourceProcess)
//       return;
//     this.reRank(sourceProcess);
//   }
// 
//   handleStartMoveProcess() {
//     this.setState({ PreMoveProcessesJson: JSON.stringify(this.state.Processes) });
//   }
// 
//   handleAbortMoveProcess() {
//     this.setState({ Processes: JSON.parse(this.state.PreMoveProcessesJson) });
//   }
// 
//   handleMoveProcess(sourceProcess, targetProcess) {
//     if (!sourceProcess)
//       return;
//     // console.log("Source Process: ", sourceProcess.Name);
//     if (targetProcess) {
//       // console.log("Target Process: ", targetProcess.Name);
//       if (sourceProcess.Rank === targetProcess.Rank)
//         return;
//     }
// 
//     let processes = this.state.Processes;
//     let sourceIndex = processes.indexOf(sourceProcess);
//     let targetIndex = processes.indexOf(targetProcess);
//     if (sourceIndex === targetIndex)
//       return;
// 
//     //console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
//     if (sourceIndex === null || targetIndex === null)
//       return;
// 
//     processes.splice(targetIndex, 1);
//     processes.splice(sourceIndex, 0, targetProcess);
//     this.setState({ Processes: processes });
// 	}

  handleCardAction(processKey) {
    this.props.history.push("/admin/process/" + processKey)
  }

  componentDidMount() {
    // If the team was passed in via querystring, change the active team to the passed value
    // const values = queryString.parse(this.props.location.search);
    // if (values.team) {
    //   this.handleActiveTeamKeyChange(values.team, true);
    // }
    // else {
    //   this.GetUserPreferences();
    // }
    this.handleGetProcesses();
  }
  
  render() {
    const {
      ApiError,
      Alert,
      Confirmation,
      Processes,
      Cursor,
    } = this.state;
    const { 
      classes,
    //   connectDropTarget,
    } = this.props;

    let processCards = [];
    if (Processes.length > 0) {
      processCards = this.state.Processes.map(process =>
        (
          <Grid item key={process.Key} xs={4}>
            <ProcessCard Process={process}
              onCardAction={() => this.handleCardAction(process.Key)}
              // onDeleteProcess={() => this.handleDeleteProcess(process)}
              // onUpdateProcess={e => this.handleUpdateProcess(process, e)}
              // onStartMoveProcess={() => this.handleStartMoveProcess()}
              // onAbortMoveProcess={() => this.handleAbortMoveProcess()}
              // onMoveProcess={processTarget => this.handleMoveProcess(process, processTarget)}
              // onDropProcess={() => this.handleDropProcess(process)}
            >
            </ProcessCard>
          </Grid>
        )
      );
    }

    let loadMoreButton = null;
    if (Cursor) {
      loadMoreButton = (
        <Button fullWidth color="primary" className={classes.button}
          onClick={() => this.handleGetProcesses()}>
          LOAD MORE
        </Button>
      );
    }

    // return connectDropTarget(
    return (
      <div>
        <UiCore title="Processes"
          apiError={ApiError}
          alert={Alert}
          confirmation={Confirmation}
        />

        <Fab color="primary" aria-label="Add Process" className={classes.fab}
          onClick={() => this.handleConfirmAddProcess()}>
          <AddIcon />
        </Fab>

        <div style={{padding: 24}}>
          <Grid container spacing={24}>
          {processCards}
          </Grid>
        
          {loadMoreButton}
        </div>
        
      </div>
    );
  }
}

Processes.propTypes = {
  classes: PropTypes.object.isRequired,
};

// export default DropTarget('ProcessCard', gridTarget, dropCollect)(Processes);
export default withStyles(styles, {withTheme: true})(Processes);