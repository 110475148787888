import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import UiCore from '../../Components/UiCore';
// import StageCard from '../Components/StageCard';
import DraggableListItem from '../Components/DraggableListItem';

import { DropTarget } from 'react-dnd';

import API from '../../Util/api';
import TaskType from '../../Model/TaskType';

import PropTypes from 'prop-types';

// import AddIcon from '@material-ui/icons/Add';
// import ConnectorIcon from '@material-ui/icons/TrendingFlat';

import { withStyles } from '@material-ui/core/styles';

import {
  mxGraph,
  mxRubberband,
  mxKeyHandler,
  mxClient,
  mxUtils,
  mxEdgeHandler,
  mxEvent,
  mxCircleLayout,
  mxConnectionHandler,
  mxConstraintHandler,
  mxFastOrganicLayout,
  mxMorphing,
  mxStackLayout,
  mxPoint,
  mxConnectionConstraint,
  mxCellState,
  mxConstants,
  mxShape,
  mxPolyline,
  mxPerimeter,
  // mxRectangle,
} from "mxgraph-js";

// const rankIncrement = 1000;

const styles = theme => ({
  textField: {
    //marginTop: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
  destructiveButton: {
    margin: theme.spacing.unit,
    color: "#E53935",
    borderColor: "#E53935",
  },
  toolPane: {
    backgroundColor: "#efefef",
    height: "100%",
  },
  fab: {
    position: 'fixed',
    zIndex: 1,
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

const divTarget = {
  drop(props, monitor, component) {
    if (monitor.didDrop()) {
      // If you want, you can check whether some nested
      // target already handled drop
      return;
    } 

    switch (monitor.getItemType()) {
      case "DraggableListItem":
        const draggableListItem = monitor.getItem();
        draggableListItem.onDrop(monitor.getClientOffset(), draggableListItem.TaskType);
        break;
      default:
        break;
    }
    
    // You can also do nothing and return a drop result,
    // which will be available as monitor.getDropResult()
    // in the drag source's endDrag() method
    //return { moved: true };
  },
}

/**
 * Specifies which props to inject into your component.
 */
function dropCollect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connect.dropTarget(),
    // You can ask the monitor about the current drag state:
    //isOver: monitor.isOver(),
    // isOverCurrent: monitor.isOver({ shallow: true }),
    // canDrop: monitor.canDrop(),
    // itemType: monitor.getItemType()
  };
}

class Process extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      Process: {
        Name:""
      },
      //Stages: [],
      Tasks: [],
      //TasksByStageKey: [/*{ StageKey: "", Tasks: [] }*/],
      //PreMoveStagesJson: "",
    }

    this.loadGraph = this.loadGraph.bind(this);

    this.isDirty = false;
    this.isClearAllTasks = false;

    this.graph = null;
  }

  handleGetProcess(key) {
    return API.get("/processes/" + key, 
      { 
        params: { 
        }
      }
    )
      .then(resp => { 
        this.setState({ Process: resp.data });
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handlePropertyBlur() {
    if (this.isDirty) {
      this.handleUpdateProcess();
    }
  }

  handleUpdateProcess(process) {
    if (!process) {
      process = {...this.state.Process};
    }
    
    return API.put("/processes/" + process.Key, process,
      { 
        // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
        this.isDirty = false;
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleConfirmDeleteProcess() {
    this.setState({
      Confirmation: {
        Title: "Delete Process",
        BodyText: "This action cannot be undone. This will permanently delete this process.",
        BodyClassName: "warning",
        ConfirmCallback: () => this.handleDeleteProcess(),
      }
    });
  }

  handleDeleteProcess() {
    let process = {...this.state.Process};
    API.delete("/processes/" + process.Key,
      { 
        params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
        // Timeout to allow time for eventual consistency
        setTimeout(() => this.props.history.push("/admin/processes"), 250);
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleConfirmProcessName() {
    this.setState({
      Confirmation: {
        Title: "Edit Process Name",
        RequireTextInput1: true,
        TextInput1Label: "Process Name",
        TextInput1DefaultValue: this.state.Process.Name,
        ConfirmLabel: "GO",
        ConfirmCallback: (textInput1) => this.handleUpdateProcessName(textInput1),
      }
    });
  }

  handleUpdateProcessName(name) {
    if (!name)
      return;
    let process = {...this.state.Process};
    process.Name = name;
    this.handleUpdateProcess(process)
      .then(resp => {
        this.setState({Process: process});
      });
  }

//   handleConfirmAddStage() {
//     this.setState({
//       Confirmation: {
//         Title: "Create Stage",
//         RequireTextInput1: true,
//         TextInput1Label: "Stage Name",
//         ConfirmLabel: "GO",
//         ConfirmCallback: (textInput1) => this.handleAddStage(textInput1),
//       }
//     });
//   }
// 
//   Stage = {
//     Key: "",
//     Name: "",
//     Rank: rankIncrement,
//   }
// 
//   getNewStage(name) {
//     let newStage = {...this.Stage};
//     newStage.Name = name;
//     if (this.state.Stages && this.state.Stages.length > 0) {
//       newStage.Rank = rankIncrement + this.state.Stages[this.state.Stages.length - 1].Rank;
//     }
//     else {
//       newStage.Rank = rankIncrement;
//     }
//     return newStage;
//   }
// 
//   handleAddStage(name) {
//     if (!name)
//       return;
//     let newStage = this.getNewStage(name);
//     return API.post("/stages", newStage, 
//       { 
//         params: { 
//           // team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined,
//           processKey: this.state.Process.Key,
//         }
//       }
//     )
//       .then(resp => {
//         newStage.Key = resp.data.Key;
//         let stages = [...this.state.Stages];
//         stages.push(newStage);
//         this.setState({Stages: stages});
//         return newStage;
//       })
//       .catch(err => this.setState({ApiError: err}));
//   }
// 
//   handleGetStages() {
//     API.get("/stages",
//       { 
//         params: { 
//           // team: (teamKey) ? teamKey : undefined, 
//           processKey: this.state.Process.Key,
//           // cursor: (this.state.Cursor) ? this.state.Cursor : undefined,
//         }
//       }
//     )
//       .then(resp => { 
//         // let stages = [...this.state.Stages];
//         // stages = stages.concat(resp.data.Data);
//         this.setState({ Stages: /*stages*/ resp.data, /*Cursor: resp.data.Cursor*/ });
//       })
//       .catch(err => this.setState({ApiError: err}));
//   }
// 
//   handleUpdateStage(stage) {
//     return API.put("/stages/" + stage.Key, stage,
//       { 
//         // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//       }
//     )
//       .then(resp => {
//       })
//       .catch(err => this.setState({ApiError: err}));  
//   }
// 
//   handleConfirmDeleteStage(stage) {
//     this.setState({
//       Confirmation: {
//         Title: "Delete Stage",
//         BodyText: "This action cannot be undone. This will permanently delete this stage.",
//         BodyClassName: "warning",
//         ConfirmCallback: () => this.handleDeleteStage(stage),
//       }
//     });
//   }
// 
//   handleDeleteStage(stage) {
//     API.delete("/stages/" + stage.Key,
//       { 
//         params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//       }
//     )
//       .then(resp => {
//         let stages = [...this.state.Stages];
//         for (let i = 0; i < stages.length; i++) {
//           if (stages[i].Key === stage.Key) {
//             stages.splice(i, 1);
//             break;
//           }
//         }
//         this.setState({Stages: stages});
//       })
//       .catch(err => this.setState({ApiError: err}));  
//   }
// 
//   handleConfirmStageName(stage) {
//     this.setState({
//       Confirmation: {
//         Title: "Edit Stage Name",
//         RequireTextInput1: true,
//         TextInput1Label: "Stage Name",
//         TextInput1DefaultValue: stage.Name,
//         ConfirmLabel: "GO",
//         ConfirmCallback: (textInput1) => this.handleUpdateStageName(stage, textInput1),
//       }
//     });
//   }
// 
//   handleUpdateStageName(stage, name) {
//     if (!name)
//       return;
//     stage.Name = name;
//     this.handleUpdateStage(stage)
//       .then(resp => {
//         let stages = [...this.state.Stages];
//         for (let i = 0; i < stages.length; i++) {
//           if (stages[i].Key === stage.Key) {
//             stages[i].Name = name;
//             break;
//           }
//         }
//         this.setState({Stages: stages});
//       });
//   }
// 
//   handleDropStage(sourceStage) {
//     if (!sourceStage)
//       return;
//     this.reRankStages(sourceStage);
//   }
// 
//   handleStartMoveStage() {
//     this.setState({ PreMoveStagesJson: JSON.stringify(this.state.Stages) });
//   }
// 
//   handleAbortMoveStage() {
//     this.setState({ Stages: JSON.parse(this.state.PreMoveStagesJson) });
//   }
// 
//   handleMoveStage(sourceStage, targetStage) {
//     if (!sourceStage)
//       return;
//     // console.log("Source Stage: ", sourceStage.Name);
//     if (targetStage) {
//       // console.log("Target Process: ", targetStage.Name);
//       if (sourceStage.Rank === targetStage.Rank)
//         return;
//     }
// 
//     let stages = this.state.Stages;
//     let sourceIndex = stages.indexOf(sourceStage);
//     let targetIndex = stages.indexOf(targetStage);
//     if (sourceIndex === targetIndex)
//       return;
// 
//     //console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
//     if (sourceIndex === null || targetIndex === null)
//       return;
// 
//     stages.splice(targetIndex, 1);
//     stages.splice(sourceIndex, 0, targetStage);
//     this.setState({ Stages: stages });
// 	}
// 
//   reRankStages(sourceStage) {
//     let stages = this.state.Stages;
//     let sourceIndex = stages.map(t => t.Key).indexOf(sourceStage.Key);
//     let startIndex = sourceIndex;
//     let endIndex = sourceIndex;
// 
//     let increment = rankIncrement;
//     let lastIncrement = -1;
//     while (true) {
//       // This protects against an endless loop
//       if (lastIncrement === increment)
//         break;
// 
//       if (startIndex > 0)
//         startIndex--;
//       if (endIndex < stages.length - 1)
//         endIndex++;
//       if (startIndex === endIndex)
//         return;
//       // console.log("index start ", startIndex, "end ", endIndex);
// 
//       let startRank = (startIndex === 0) ? 0 : stages[startIndex].Rank;
//       let endRank = stages[endIndex].Rank;
//       // console.log("rank start ", startRank, "end ", endRank);
// 
//       lastIncrement = increment;
//       increment = Math.abs((endRank - startRank) / 3);
// 
//       // console.log("increment ", increment);
// 
//       // If increment is too small, we need to cycle, which will incorporate outside items
//       if (increment >= 1) {
//         let curRank = startRank;
//         for (let i = startIndex; i <= endIndex; i++) {
//           curRank += increment;
//           stages[i].Rank = curRank;
//         }
//         break;
//       }
//     }
//     
//     this.setState({ Stages: stages});
// 
//     let preMoveStages = JSON.parse(this.state.PreMoveStagesJson);
//     for (let i = startIndex; i <= endIndex; i++) {
//       console.log("original: ", preMoveStages[i].Rank, " new: ", stages[i].Rank);
//       if (stages[i].Rank === preMoveStages[i].Rank)
//         continue;
//       API.put("/stages/" + stages[i].Key, stages[i],
//         { 
//           params: { 
//             // team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined
//           }
//         }
//       )
//         .then(resp => {
//         })
//         .catch(err => console.log(err));
//     }
//   }

  handleTextFieldValueChange(propName, e) {
    let process = Object.assign({...this.state.Process}, {[propName]: e.target.value});
    this.setState({Process: process});
    this.isDirty = true;
  }

  Task = {
    Key: "",
    Name: "",
    Type: TaskType.Basic,
    GraphX: null,
    GraphY: null,
    TaskConnections: [], /* This is a local property */
  }

  getNewTask(name, type) {
    let newTask = this.Task;
    newTask.Key = new Date();
    newTask.Name = name;
    newTask.Type = type;
    return newTask;
  }

  handleAddTask(/*stage, */clientOffset, taskType) {
    let newTask = {...this.getNewTask(taskType.Name, taskType.Id)};

    let pt = this.graph.getPointForEvent(new MouseEvent('click', { clientX: clientOffset.x, clientY: clientOffset.y}));
    newTask.GraphX = pt.x - (taskType.Width / 2);
    newTask.GraphY = pt.y - (taskType.Height / 2);

    let vertex = this.handleAddTaskAsCell(newTask, taskType);

    API.post("/tasks", newTask, 
      { 
        params: { 
          // team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined,
          processKey: this.state.Process.Key,
        }
      }
    )
      .then(resp => {
        let tasks = [...this.state.Tasks];
        tasks.push(resp.data);
        this.setState({Tasks: tasks});
        // Update vertex id with real key
        vertex.id = resp.data.Key;
      })
      .catch(err => this.setState({ApiError: err}));

    // let tasksByStageKey = [...this.state.TasksByStageKey]; 
    // let foundKey = false;
    // for (let i = 0; i < tasksByStageKey.length; i++) {
    //   if (tasksByStageKey[i].StageKey === stage.Key) {
    //     foundKey = true;
    //     tasksByStageKey[i].Tasks.push(newTask);
    //     break;
    //   }
    // }
    // if (!foundKey) {
    //   tasksByStageKey.push({ StageKey: stage.Key, Tasks: [newTask] });
    // }
    // this.setState({TasksByStageKey: tasksByStageKey});
  }

  getTaskByKey(tasks, key) {
    let t = tasks.filter(t => t.Key === key);
    if (t.length)
      return t[0];
    return null;
  }

  handleGetTasks() {
    return API.get("/tasks",
      { 
        params: { 
          // team: (teamKey) ? teamKey : undefined, 
          processKey: this.state.Process.Key,
          // cursor: (this.state.Cursor) ? this.state.Cursor : undefined,
        }
      }
    )
      .then(resp => { 
        // let stages = [...this.state.Stages];
        // stages = stages.concat(resp.data.Data);
        this.setState({ Tasks: resp.data, /*Cursor: resp.data.Cursor*/ });
        return resp.data;
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handleUpdateTask(task) {
    return API.put("/tasks/" + task.Key, task,
      { 
        // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleDeleteTask(task) {
    API.delete("/tasks/" + task.Key,
      { 
        // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleConfirmClearTasks() {
    this.setState({
      Confirmation: {
        Title: "Clear All Tasks",
        BodyText: "This action cannot be undone. This will permanently delete all tasks.",
        BodyClassName: "warning",
        ConfirmCallback: () => this.handleClearTasks(),
      }
    });
  }

  handleClearTasks() {
    let process = {...this.state.Process};
    API.delete("/tasks",
      { 
        params: { 
          // team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined 
          processKey: process.Key,
        }
      }
    )
      .then(resp => {
        this.setState({ Tasks: [] });
        this.isClearAllTasks = true;
        this.graph.removeCells(this.graph.getChildVertices(this.graph.getDefaultParent()));
        this.isClearAllTasks = false;
      })
      .catch(err => this.setState({ApiError: err}));  
  }

//   handleAddTaskToNewStage(taskType) {
//     if (!taskType)
//       return;
// 
//     this.handleAddStage("Stage")
//       .then(resp => {
//         this.handleAddTask(resp, "Task", taskType);
//       });
//   }

TaskConnection = {
    Key: "",
    Name: "",
    Type: null,
    TargetTaskKey: null,
  }

  getNewTaskConnection(targetTaskKey, type) {
    let newTaskConnection = this.TaskConnection;
    newTaskConnection.Key = new Date();
    newTaskConnection.Type = type;
    newTaskConnection.TargetTaskKey = targetTaskKey;
    return newTaskConnection;
  }

  handleAddTaskConnectionAndReturn(taskKey, targetTaskKey, taskConnectionType) {
    let newTaskConnection = {...this.getNewTaskConnection(targetTaskKey, taskConnectionType)};

    return API.post("/taskConnections", newTaskConnection, 
      { 
        params: { 
          // team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined,
          taskKey: taskKey,
        }
      }
    )
      .then(resp => {
        this.addTaskConnectionsToTasksAndReturnTasks([...this.state.Tasks], taskKey, [resp.data]);
        return resp.data;
      })
      .catch(err => this.setState({ApiError: err}));
  }

  addTaskConnectionsToTasksAndReturnTasks(tasks, taskKey, taskConnections) {
    let task = this.getTaskByKey(tasks, taskKey);
    if (task) {
      if (task.TaskConnections) {
        taskConnections.map(tc => task.TaskConnections.push(tc));
      }
      else {
        task.TaskConnections = taskConnections;
      }
      this.setState({ Tasks: tasks });
    }
    return tasks;
  }

  getTaskConnectionByKey(tasks, key) {
    let t = tasks.filter(t => (typeof t.TaskConnections !== "undefined" && t.TaskConnections.filter(tc => tc.Key === key)));
    if (t.length) {
      let tc = t[0].TaskConnections.filter(tc => tc.Key === key);
      if (tc.length) {
        return tc[0];
      }
    }
    return null;
  }

  handleGetTaskConnectionsAndReturnTasks(tasks) {
    return API.get("/taskConnections",
      { 
        params: { 
          // team: (teamKey) ? teamKey : undefined, 
          processKey: this.state.Process.Key,
          // cursor: (this.state.Cursor) ? this.state.Cursor : undefined,
        }
      }
    )
      .then(resp => {
        resp.data.map(taskConnection => {
          tasks = this.addTaskConnectionsToTasksAndReturnTasks(tasks, taskConnection.SourceTaskKey, [taskConnection]);
          return tasks;
        });
        return tasks;
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handleUpdateTaskConnection(taskConnection) {
    return API.put("/taskConnections/" + taskConnection.Key, taskConnection,
      { 
        // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleDeleteTaskConnection(taskConnectionKey) {
    API.delete("/taskConnections/" + taskConnectionKey,
      { 
        // params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
      })
      .catch(err => this.setState({ApiError: err}));  
  }

  handleAddTaskAsCell(task, taskType) {
    let model = this.graph.getModel();
    let parent = this.graph.getDefaultParent();
    let vertex = null;

    model.beginUpdate();
    try {
      // let doc = mxUtils.createXmlDocument();
      // let node = doc.createElement("Node");
      // node.setAttribute("ComponentID", "[P01]");

      vertex = this.graph.insertVertex(parent, task.Key, task.Name, task.GraphX, task.GraphY, taskType.Width, taskType.Height);
      if (taskType.VertexStyle)
        model.setStyle(vertex, taskType.VertexStyle);

//       let saveToDrive = this.graph.insertVertex(parent, null, "Save to\n Google Drive", 248, 48, 120, 60);
//       model.setStyle(saveToDrive, 'task');
// 
//       this.graph.insertEdge(parent, null, "", processStart, saveToDrive);
    } finally {
      // Updates the display
      model.endUpdate();
    }

    return vertex;
  }

  handleCellsMoved(sender, evt) {
    if (!evt.properties.cells || !evt.properties.cells.length)
      return;
    let tasks = [...this.state.Tasks];
    for (let i = 0; i < evt.properties.cells.length; i++) {
      let cell = evt.properties.cells[i];
      if (!cell.vertex)
        continue;
      let task = this.getTaskByKey(tasks, cell.id);
      task.GraphX = cell.geometry.x;
      task.GraphY = cell.geometry.y;
      this.handleUpdateTask(task);
    }
    this.setState({ Tasks: tasks });
  }

  handleEdgeCreateAndConnect(sender, evt) {
    if (!evt.properties.cell)
      return;
    let edge = evt.properties.cell;
    let sourceVertex = this.graph.getModel().getTerminal(edge, true);
    let targetVertex = this.graph.getModel().getTerminal(edge, false);
    this.handleAddTaskConnectionAndReturn(sourceVertex.id, targetVertex.id)
      .then(taskConnection => {
        edge.id = taskConnection.Key;
      });
  }

  handleEdgeMove(sender, evt) {
    if (!evt.properties.previous
      || evt.properties.terminal === evt.properties.previous)
      return;
    let edge = evt.properties.edge;
    let sourceVertex = this.graph.getModel().getTerminal(edge, true);
    let targetVertex = this.graph.getModel().getTerminal(edge, false);
    let originalVertex = evt.properties.previous;
    let newVertex = evt.properties.terminal;
    let tasks = [...this.state.Tasks];

    // Moving connection - source side
    if (evt.properties.source) {
      let newTask = this.getTaskByKey(tasks, newVertex.id);
      // Remove connection from originalTask
      this.handleDeleteTaskConnection(edge.id);
      let originalTask = this.getTaskByKey(tasks, originalVertex.id);
      originalTask.TaskConnections = originalTask.TaskConnections.filter(t => t.Key !== edge.id);
      this.setState({ Tasks: tasks });
      // Add connection to newTask
      if (!newTask.TaskConnections)
        newTask.TaskConnections = [];
      this.handleAddTaskConnectionAndReturn(newTask.Key, targetVertex.id);
    }

    // Moving connection - target side
    else {
      // Remove connection to original target
      this.handleDeleteTaskConnection(edge.id);
      let sourceTask = this.getTaskByKey(tasks, sourceVertex.id);
      sourceTask.TaskConnections = sourceTask.TaskConnections.filter(t => t.Key !== edge.id);
      this.setState({ Tasks: tasks });
      // Add connection to new target
      sourceTask.TaskConnections.push(newVertex.id);
      this.handleAddTaskConnectionAndReturn(sourceTask.Key, newVertex.id);
    }
  }

  handleCellsRemoved(sender, evt) {
    if (this.isClearAllTasks)
      return;
    if (!evt.properties.cells || !evt.properties.cells.length)
      return;
    let tasks = [...this.state.Tasks]
    for (let i = 0; i < evt.properties.cells.length; i++) {
      let cell = evt.properties.cells[i];
      // Vertex
      if (cell.vertex) {
        let task = this.getTaskByKey(tasks, cell.id);
        this.handleDeleteTask(task);
        this.setState({ Tasks: tasks.filter(t => t.Key !== task.Key) });
      }
      // Edge
      if (cell.edge) {
        let tasks = [...this.state.Tasks];
        let sourceVertex = this.graph.getModel().getTerminal(cell, true);
        let sourceTask = this.getTaskByKey(tasks, sourceVertex.id);
        if (sourceTask) {
          this.handleDeleteTaskConnection(cell.id);
          sourceTask.TaskConnections = sourceTask.TaskConnections.filter(t => t.Key !== cell.id);
          this.setState({ Tasks: tasks });
        }
      }
    }
  }

  handleLabelChanged(sender, evt) {
    if (!evt.properties.cell)
      return;
    let cell = evt.properties.cell;
    let tasks = [...this.state.Tasks];
    // Vertex
    if (cell.vertex) {
      let task = this.getTaskByKey(tasks, cell.id);
      task.Name = cell.value;
      this.handleUpdateTask(task);
      this.setState({ Tasks: tasks });
    }
    // Edge
    else if (cell.edge) {
      let taskConnection = this.getTaskConnectionByKey(tasks, cell.id);
      taskConnection.Name = cell.value;
      this.handleUpdateTaskConnection(taskConnection)
      this.setState({ Tasks: tasks });
    }
  }

  handleCircleLayout() {
    let layout = new mxCircleLayout(this.graph);
    layout.disableEdgeStyle = false;
    this.executeLayout(layout);
  }

  handleFastOrganicLayout() {
    let layout = new mxFastOrganicLayout(this.graph);
    layout.forceConstant = 120;
    layout.disableEdgeStyle = false;
    this.executeLayout(layout);
  }

  handleStackLayout() {
    let layout = new mxStackLayout(this.graph);
    layout.horizontal = false;
    layout.spacing = 50;
    this.executeLayout(layout);
  }

  executeLayout(layout) {
    let model = this.graph.getModel();
    let parent = this.graph.getDefaultParent();
    model.beginUpdate();
    try
    {
      layout.execute(parent);
    }
    catch (e)
    {
      throw e;
    }
    finally
    {
      // Default values are 6, 1.5, 20
      var morph = new mxMorphing(this.graph, 10, 1.7, 20);
      morph.addListener(mxEvent.DONE, function()
      {
        this.graph.getModel().endUpdate();
      });
      morph.startAnimation();
    }
  }

  loadGraph(tasks) {
    //let container = ReactDOM.findDOMNode(this.refs.divGraph);
    let container = this.divGraph;
    
    // console.log(container);
    // let zoomPanel = ReactDOM.findDOMNode(this.refs.divZoom);

    // Checks if the browser is supported
    if (!mxClient.isBrowserSupported()) {
      // Displays an error message if the browser is not supported.
      mxUtils.error("Browser is not supported!", 200, false);
    } else {
      // Disables the built-in context menu
      mxEvent.disableContextMenu(container);

      // Snaps to fixed points
      mxConstraintHandler.prototype.intersects = function(icon, point, source, existingEdge)
      {
        return (!source || existingEdge) || mxUtils.intersects(icon.bounds, point);
      };
      
      // Special case: Snaps source of new connections to fixed points
      // Without a connect preview in connectionHandler.createEdgeState mouseMove
      // and getSourcePerimeterPoint should be overriden by setting sourceConstraint
      // sourceConstraint to null in mouseMove and updating it and returning the
      // nearest point (cp) in getSourcePerimeterPoint (see below)
      let mxConnectionHandlerUpdateEdgeState = mxConnectionHandler.prototype.updateEdgeState;
      mxConnectionHandler.prototype.updateEdgeState = function(pt, constraint)
      {
        if (pt != null && this.previous != null)
        {
              let constraints = this.graph.getAllConnectionConstraints(this.previous);
              let nearestConstraint = null;
              let dist = null;
         
              for (let i = 0; i < constraints.length; i++)
              {
                  let cp = this.graph.getConnectionPoint(this.previous, constraints[i]);
                 
                  if (cp != null)
                  {
                      let tmp = (cp.x - pt.x) * (cp.x - pt.x) + (cp.y - pt.y) * (cp.y - pt.y);
                 
                      if (dist == null || tmp < dist)
                      {
                        nearestConstraint = constraints[i];
                          dist = tmp;
                      }
                  }
              }
             
              if (nearestConstraint != null)
              {
                this.sourceConstraint = nearestConstraint;
              }
              
              // In case the edge style must be changed during the preview:
              // this.edgeState.style['edgeStyle'] = 'orthogonalEdgeStyle';
              // And to use the new edge style in the new edge inserted into the graph,
              // update the cell style as follows:
              //this.edgeState.cell.style = mxUtils.setStyle(this.edgeState.cell.style, 'edgeStyle', this.edgeState.style['edgeStyle']);
        }
      
        mxConnectionHandlerUpdateEdgeState.apply(this, arguments);
      };

      // Creates the graph inside the given container
      if (!this.graph) {
        this.graph = new mxGraph(container);

        // Enables rubberband selection
        //new mxRubberband(graph);

        // Enables tooltips, new connections and panning
        this.graph.setPanning(true);
        this.graph.setTooltips(false);
        this.graph.setConnectable(true);
        this.graph.setEnabled(true);
        this.graph.setEdgeLabelsMovable(false);
        this.graph.setVertexLabelsMovable(false);
        this.graph.setGridEnabled(true);
        this.graph.setAllowDanglingEdges(false);
        this.graph.setCellsCloneable(false);
        this.graph.setCellsResizable(false);

        //this.graph.connectionHandler.connectImage = new mxImage('images/connector.gif', 16, 16);
        
        // Disables floating connections (only use with no connect image)
        if (this.graph.connectionHandler.connectImage == null)
        {
          this.graph.connectionHandler.isConnectableCell = function(cell)
          {
             return false;
          };
          mxEdgeHandler.prototype.isConnectableCell = function(cell)
          {
            return this.graph.connectionHandler.isConnectableCell(cell);
          };
        }
        
        // Overridden to define per-shape connection points
        mxGraph.prototype.getAllConnectionConstraints = function(terminal, source)
        {
          if (terminal != null && terminal.shape != null)
          {
            if (terminal.shape.stencil != null)
            {
              return terminal.shape.stencil.constraints;
            }
            else if (terminal.shape.constraints != null)
            {
              return terminal.shape.constraints;
            }
          }
      
          return null;
        };

        // Defines the default constraints for all shapes
        mxShape.prototype.constraints = 
          [
            new mxConnectionConstraint(new mxPoint(0.25, 0), true),
            new mxConnectionConstraint(new mxPoint(0.5, 0), true),
            new mxConnectionConstraint(new mxPoint(0.75, 0), true),
            // new mxConnectionConstraint(new mxPoint(0, 0.25), true),
            new mxConnectionConstraint(new mxPoint(0, 0.5), true),
            // new mxConnectionConstraint(new mxPoint(0, 0.75), true),
            // new mxConnectionConstraint(new mxPoint(1, 0.25), true),
            new mxConnectionConstraint(new mxPoint(1, 0.5), true),
            // new mxConnectionConstraint(new mxPoint(1, 0.75), true),
            new mxConnectionConstraint(new mxPoint(0.25, 1), true),
            new mxConnectionConstraint(new mxPoint(0.5, 1), true),
            new mxConnectionConstraint(new mxPoint(0.75, 1), true)
          ];

        // Edges have no connection points
        mxPolyline.prototype.constraints = null;

        // this.graph.getAllConnectionConstraints = function(terminal)
        // {
        //   if (terminal != null && this.model.isVertex(terminal.cell))
        //   {
        //     return [
        //       new mxConnectionConstraint(new mxPoint(0, 0), true),
        //       new mxConnectionConstraint(new mxPoint(0.5, 0), true),
        //       new mxConnectionConstraint(new mxPoint(1, 0), true),
        //       new mxConnectionConstraint(new mxPoint(0, 0.5), true),
        //       new mxConnectionConstraint(new mxPoint(1, 0.5), true),
        //       new mxConnectionConstraint(new mxPoint(0, 1), true),
        //       new mxConnectionConstraint(new mxPoint(0.5, 1), true),
        //       new mxConnectionConstraint(new mxPoint(1, 1), true)
        //     ];
        //   }
        //   return null;
        // };

        // Enables connect preview for the default edge style
        this.graph.connectionHandler.createEdgeState = function(me)
        {
          let edge = this.graph.createEdge(null, null, null, null, null);
          
          return new mxCellState(this.graph.view, edge, this.graph.getCellStyle(edge));
        };

        // Changes the default style for edges "in-place" and assigns
        // an alternate edge style which is applied in mxGraph.flip
        // when the user double clicks on the adjustment control point
        // of the edge. The ElbowConnector edge style switches to TopToBottom
        // if the horizontal style is true.
        let defaultEdgeStyle = this.graph.getStylesheet().getDefaultEdgeStyle();
        // Specifies the default edge style
        defaultEdgeStyle[mxConstants.STYLE_EDGE] = mxConstants.EDGESTYLE_ORTHOGONAL;
        defaultEdgeStyle[mxConstants.STYLE_ROUNDED] = true;
        defaultEdgeStyle[mxConstants.STYLE_FONTFAMILY] = "Roboto,Arial,Helvetica,sans-serif";
        defaultEdgeStyle[mxConstants.STYLE_FONTSIZE] = "14";
        defaultEdgeStyle[mxConstants.STYLE_FONTCOLOR] = "rgba(0, 0, 0, 0.87)";

        let defaultVertexStyle = this.graph.getStylesheet().getDefaultVertexStyle();
        defaultVertexStyle[mxConstants.STYLE_FONTFAMILY] = "Roboto,Arial,Helvetica,sans-serif";
        defaultVertexStyle[mxConstants.STYLE_FONTSIZE] = "14";
        defaultVertexStyle[mxConstants.STYLE_FONTCOLOR] = "rgba(0, 0, 0, 0.87)";
        defaultVertexStyle[mxConstants.STYLE_SHADOW] = true;
        mxConstants.SHADOW_OPACITY = 0.5;
        mxConstants.CURSOR_CONNECT = "crosshair";

        let taskStyle = [];
        taskStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
        taskStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
        taskStyle[mxConstants.STYLE_ROUNDED] = true;
        this.graph.getStylesheet().putCellStyle('task', taskStyle);

        let terminalStyle = [];
        terminalStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_ELLIPSE;
        terminalStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.EllipsePerimeter;
        this.graph.getStylesheet().putCellStyle('terminal', terminalStyle);

        let decisionStyle = [];
        decisionStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RHOMBUS;
        decisionStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RhombusPerimeter;
        this.graph.getStylesheet().putCellStyle('decision', decisionStyle);

        // Listeners
        this.graph.addListener(mxEvent.CELLS_MOVED, this.handleCellsMoved.bind(this));
        this.graph.addListener(mxEvent.LABEL_CHANGED, this.handleLabelChanged.bind(this));
        this.graph.connectionHandler.addListener(mxEvent.CONNECT, this.handleEdgeCreateAndConnect.bind(this));
        this.graph.addListener(mxEvent.CELL_CONNECTED, this.handleEdgeMove.bind(this));
        this.graph.addListener(mxEvent.CELLS_REMOVED, this.handleCellsRemoved.bind(this));
      }
      
      // Gets the default parent for inserting new cells. This is normally the first
      // child of the root (ie. layer 0).
      let parent = this.graph.getDefaultParent();

      let model = this.graph.getModel();

      model.beginUpdate();
      try {
        //mxGraph component
        let doc = mxUtils.createXmlDocument();
        let node = doc.createElement("Node");
        node.setAttribute("ComponentID", "[P01]");

        // Vertex Pass
        let vertexesByKey = [];
        tasks.map(task => {
          let taskType = TaskType[task.Type];
          let vertex = this.graph.insertVertex(parent, task.Key, task.Name, task.GraphX, task.GraphY, taskType.Width, taskType.Height);
          model.setStyle(vertex, taskType.VertexStyle);
          vertexesByKey[task.Key] = vertex;
          return null;
        });

        // Edge Pass
        tasks.map(sourceTask => {
          if (sourceTask.TaskConnections) {
            sourceTask.TaskConnections.map(taskConnection => {
              let targetTasks = tasks.filter(t => t.Key === taskConnection.TargetTaskKey);
              if (!targetTasks.length) {
                console.log('cannot find vertex - needs cleanup logic');
                return null;
              }
              let targetTask = targetTasks[0];
              if (!vertexesByKey[sourceTask.Key] || !vertexesByKey[targetTask.Key])
                return null;
              this.graph.insertEdge(parent, taskConnection.Key, taskConnection.Name, vertexesByKey[sourceTask.Key], vertexesByKey[targetTask.Key]);            
              return null;
            });
          }
          return null;
        });

        // let edge = this.graph.insertEdge(parent, null, "", vertexes[0], vertexes[1]);
        // this.graph.setConnectionConstraint(edge, vertexes[0], true, new mxConnectionConstraint(new mxPoint(0.5, 0.75), true));
        // edge = this.graph.insertEdge(parent, null, "", vertexes[0], vertexes[2]);
        // this.graph.setConnectionConstraint(edge, vertexes[0], true, new mxConnectionConstraint(new mxPoint(0.5, 0.75), true));

//         let processStart = this.graph.insertVertex(parent, null, "Invoice\n Image\n Received", 48, 48, 120, 60);
//         model.setStyle(processStart, 'terminal');
// 
//         let saveToDrive = this.graph.insertVertex(parent, null, "Save to\n Google Drive", 248, 48, 120, 60);
//         model.setStyle(saveToDrive, 'task');
// 
//         this.graph.insertEdge(parent, null, "", processStart, saveToDrive);
// 
//         let clerkApproval = this.graph.insertVertex(parent, null, "Clerk Approval", 448, 78, 160, 80);
//         model.setStyle(clerkApproval, 'decision');
// 
//         this.graph.insertEdge(parent, null, "", saveToDrive, clerkApproval);
// 
//         let managerApproval = this.graph.insertVertex(parent, null, "Manager Approval", 708, 78, 160, 80);
//         model.setStyle(managerApproval, 'decision');
// 
//         this.graph.insertEdge(parent, null, "Deny", clerkApproval, managerApproval);
// 
//         let emailVendor = this.graph.insertVertex(parent, null, "E-mail Vendor\n Denied Invoice", 968, 88, 120, 60);
//         model.setStyle(emailVendor, 'task');
// 
//         this.graph.insertEdge(parent, null, "Deny", managerApproval, emailVendor);
// 
//         let cfoApproval = this.graph.insertVertex(parent, null, "CFO Approval", 448, 238, 160, 80);
//         model.setStyle(cfoApproval, 'decision');
// 
//         this.graph.insertEdge(parent, null, "Approve", clerkApproval, cfoApproval);
// 
//         this.graph.insertEdge(parent, null, "Deny", cfoApproval, emailVendor);
// 
//         this.graph.insertEdge(parent, null, "Approve", managerApproval, cfoApproval, 'exitX=0.5;exitY=1;entryX=0.75;entryY=0');
// 
//         let checkCutting = this.graph.insertVertex(parent, null, "Check-Cutting\n Process", 468, 388, 120, 60);
//         model.setStyle(checkCutting, 'task');
// 
//         this.graph.insertEdge(parent, null, "Approve", cfoApproval, checkCutting);
// 
//         let mailCheck = this.graph.insertVertex(parent, null, "Mail Check", 658, 388, 120, 60);
//         model.setStyle(mailCheck, 'task');
// 
//         this.graph.insertEdge(parent, null, "", checkCutting, mailCheck);
// 
//         let archive = this.graph.insertVertex(parent, null, "Archive", 808, 488, 120, 60);
//         model.setStyle(archive, 'terminal');
// 
//         this.graph.insertEdge(parent, null, "", mailCheck, archive);

        //data
      } finally {
        // Updates the display
        model.endUpdate();
      }

      // Enables rubberband (marquee) selection
      // let rubberband = 
      new mxRubberband(this.graph);
      
      // Enables key handler for basic keystrokes
      let graph = this.graph;
      let keyHandler = new mxKeyHandler(graph);
      // Delete
      keyHandler.bindKey(46, function(evt)
      {
        if (graph.isEnabled())
        {
          graph.removeCells();
        }
      });
    }
  }

  componentDidMount() {
    this.handleGetProcess(this.props.match.params.processKey)
      .then(resp => {
        this.handleGetTasks()
          .then(tasks => { 
            this.handleGetTaskConnectionsAndReturnTasks(tasks)
              .then(tasks => {
                this.loadGraph(tasks);
              });
          });

      })
      .catch(err => this.setState({ApiError: err}));
  }
  
  render() {
    const {
      ApiError,
      Alert,
      Confirmation,
      Process,
      // Stages,
      // TasksByStageKey,
    } = this.state;
     const { 
       classes,
       connectDropTarget,
    } = this.props;

    // let stageCardGridItems = [];
    // if (Stages.length > 0) {
    //   stageCardGridItems = Stages.map(stage => {
    //     let tasksByStageKey = TasksByStageKey.filter(task => task.StageKey === stage.Key);
    //     let tasks = tasksByStageKey;
    //     if (tasks.length > 0) {
    //       tasks = tasks[0].Tasks;
    //     }
    //     return (
    //       <Grid item key={stage.Key}>
    //         <StageCard 
    //           Stage={stage}
    //           Tasks={tasks}
    //           onShowConfirmation={confirmation => this.setState({Confirmation: confirmation})}
    //           onAddTask={(name, taskType) => this.handleAddTask(stage, name, taskType)}
    //           // onCardAction={() => this.handleCardAction(stage.Key)}
    //           onEditStageName={(stage) => this.handleConfirmStageName(stage)}
    //           onDeleteStage={() => this.handleConfirmDeleteStage(stage)}
    //           // onUpdateStage={e => this.handleUpdate(stage, e)}
    //           onStartMove={() => this.handleStartMoveStage()}
    //           onAbortMove={() => this.handleAbortMoveStage()}
    //           onMove={stageTarget => this.handleMoveStage(stage, stageTarget)}
    //           onDrop={() => this.handleDropStage(stage)}
    //         >
    //         </StageCard>
    //       </Grid>
    //     )
    //   });
    // }

    let triggerListItems = Object.keys(TaskType).map(taskTypeKey => {
      let taskType = TaskType[taskTypeKey];
      if (!taskType.IsTrigger)
        return null;
      return (
        <DraggableListItem 
          key={taskType.Id}
          TaskType={taskType} 
          Icon={taskType.Icon} 
          Text={taskType.Name}
          /*onDrop={(stage, taskType) => this.handleConfirmAddTask(stage, taskType)}*/
          onDrop={(clientOffset, taskType) => this.handleAddTask(clientOffset, taskType)}
        />
      );
    });

    let taskListItems = Object.keys(TaskType).map(taskTypeKey => {
      let taskType = TaskType[taskTypeKey];
      if (taskType.IsTrigger)
        return null;
      return (
        <DraggableListItem 
          key={taskType.Id}
          TaskType={taskType} 
          Icon={taskType.Icon} 
          Text={taskType.Name}
          /*onDrop={(stage, taskType) => this.handleConfirmAddTask(stage, taskType)}*/
          onDrop={(clientOffset, taskType) => this.handleAddTask(clientOffset, taskType)}
        />
      );
    });

    return (
      <div style={{height:"100%"}}>
        <UiCore
          title="Process Designer"
          subtitle={Process.Name}
          onEditSubtitle={() => this.handleConfirmProcessName()}
          apiError={ApiError}
          alert={Alert}
          confirmation={Confirmation}
        />

        {/* <Fab color="primary" aria-label="Add Stage" className={classes.fab} */}
        {/*   onClick={() => this.handleConfirmAddStage()}> */}
        {/*   <AddIcon /> */}
        {/* </Fab> */}

        <div style={{height:"calc(100% - 64px)"}}>
          
          <div style={{width:300,float:"left"}} className={classes.toolPane}>
            <Grid container direction="column" style={{padding:24,marginTop:0}}>

              {/* <Grid item> */}
              {/*   <TextField */}
              {/*     className={classes.textField} */}
              {/*     fullWidth */}
              {/*     InputLabelProps={{ shrink: true }} */}
              {/*     label="Process Name" */}
              {/*     onChange={(e) => this.handleTextFieldValueChange("Name", e)} */}
              {/*     onBlur={() => this.handlePropertyBlur()} */}
              {/*     value={Process.Name} */}
              {/*     onKeyPress={(e) => {if (e.key === "Enter") { e.target.blur(); }}} */}
              {/*   /> */}
              {/* </Grid> */}

              <Grid item /*style={{marginTop:16}}*/>
                <List component="nav"
                  subheader={<ListSubheader component="div">Triggers</ListSubheader>}>
                  {triggerListItems}

                  {/* <DraggableListItem */}
                  {/*   key="connector" */}
                  {/*   Icon={<ConnectorIcon />}  */}
                  {/*   Text="Connector" */}
                  {/*   onDrop={(clientOffset) => this.handleAddConnector(clientOffset)} */}
                  {/* /> */}
                </List>
              </Grid>

              <Grid item /*style={{marginTop:16}}*/>
                <List component="nav"
                  subheader={<ListSubheader component="div">Tasks</ListSubheader>}>
                  {taskListItems}

                  {/* <DraggableListItem */}
                  {/*   key="connector" */}
                  {/*   Icon={<ConnectorIcon />}  */}
                  {/*   Text="Connector" */}
                  {/*   onDrop={(clientOffset) => this.handleAddConnector(clientOffset)} */}
                  {/* /> */}
                </List>
              </Grid>

              <Grid item style={{marginTop: 24}}>
                {/* <Button color="primary" className={classes.button} variant="outlined" fullWidth */}
                {/*   onClick={() => this.handleCircleLayout()}> */}
                {/*   CIRCLE LAYOUT */}
                {/* </Button> */}
                <Button color="primary" className={classes.button} variant="outlined" fullWidth
                  onClick={() => this.handleFastOrganicLayout()}>
                  ORGANIC LAYOUT
                </Button>
                {/* <Button color="primary" className={classes.button} variant="outlined" fullWidth */}
                {/*   onClick={() => this.handleStackLayout()}> */}
                {/*   STACK LAYOUT */}
                {/* </Button> */}
              </Grid>

              <Grid item style={{marginTop: 48}}>
                <Button color="primary" className={classes.destructiveButton} variant="outlined" fullWidth
                  onClick={() => this.handleConfirmClearTasks()}>
                  CLEAR TASKS
                </Button>
              </Grid>

              <Grid item>
                <Button color="primary" className={classes.destructiveButton} variant="outlined" fullWidth
                  onClick={() => this.handleConfirmDeleteProcess()}>
                  DELETE PROCESS
                </Button>
              </Grid>

            </Grid>
          </div>

          {/* <div id="grid" style={{float:"left",height:"100%",width:"calc(100% - 300px)"}} */}
          {/*   ref={instance => { connectDropTarget(ReactDOM.findDOMNode(instance)); }}> */}
          {/*   <Grid container direction="column" style={{height:"100%",backgroundImage:"url(/mxgraph/images/grid.gif)"}}> */}
          {/*     {/* {stageCardGridItems} *//*} */}
          {/*     <div ref="divGraph" id="divGraph" style={{overflow:"scroll",height:"100%"}} /> */}
          {/*   </Grid> */}
          {/* </div> */}

          <div ref={instance => { connectDropTarget(ReactDOM.findDOMNode(instance)); this.divGraph = instance; }}
           id="divGraph" style={{
              float:"left",
              backgroundImage:"url(/mxgraph/images/grid.gif)",
              width:"calc(100% - 300px)",
              height:"100%",
              overflow:"scroll",
              }} />

        </div>
      </div>
    );
  }
}

Process.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DropTarget(['DraggableListItem'], divTarget, dropCollect)(withStyles(styles, {withTheme: true})(Process));