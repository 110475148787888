import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import GoogleLogin from 'react-google-login';

import API from '../Util/api';

import MultiUseDialog from '../Components/MultiUseDialog';

import './Login.css';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToReferrer: false,
      DialogDetails: {
        Open: false,
        Title: "",
        BodyText: "",
      }
    }
  }

  handleApiError(err) {
    console.log(err.response.status);
    console.log(err.response.data.message);

    switch(err.response.status) {
      case 401:
      case 500:
      default:
        this.setState({ 
          DialogDetails: {
            Open: true,
            Title: "There seems to be a problem...",
            BodyText: err.response.data.message,
          }
        });
        break;
    }
  }

  closeDialog() {
    this.setState({ DialogDetails: { Open: false }})
  }

  handleGoogleLoginResponse(response) {
    // console.log(response);

    if (!response || !response.tokenId)
      return;
  
    let googleSignin = {
      IdToken: response.tokenId
    }

    API.post("/user/login", googleSignin)
        .then(resp => {
          if (resp.status === 200) {
            this.setState({ redirectToReferrer: true });
          }
        })
        .catch(err => this.handleApiError(err));
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { redirectToReferrer, DialogDetails } = this.state;

    if (redirectToReferrer === true) {
      return (
        <Redirect to={from} />
      );
    }

    return (
       <div style={{textAlign: "center", width: "50%", margin: "auto", paddingTop: 100}}>
        <MultiUseDialog
          title={DialogDetails.Title}
          text={DialogDetails.BodyText}
          open={DialogDetails.Open}
          onClose={() => this.closeDialog()}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENTID}
          onSuccess={resp => this.handleGoogleLoginResponse(resp)}
          onFailure={resp => this.handleGoogleLoginResponse(resp)}
          className="gButton">
          <div>
            <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18"><g><path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path><path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z" fill="#34A853"></path><path d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z" fill="#FBBC05"></path><path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z" fill="#EA4335"></path></g></svg>
            <span>Sign In</span>
          </div>
        </GoogleLogin>
      </div>
    );
  }
}

export default Login;
