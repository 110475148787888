import React, { Component } from 'react';
// import { Redirect } from 'react-router-dom';
// import PropTypes from 'prop-types';

// import Typography from '@material-ui/core/Typography'
// import Grid from '@material-ui/core/Grid';
// import TextField from '@material-ui/core/TextField';

import UiCore from '../Components/UiCore';
// import TaskCard from './TaskCard';

// import { DropTarget } from 'react-dnd';

// import AddTask from './AddTask';
// import AddTeam from '../Components/AddTeam';
// import Invite from '../Components/Invite';
// import TeamSelector from '../Components/TeamSelector';

import API from '../Util/api';

import queryString from 'query-string';

// const rankIncrement = 1000;

// const gridTarget = {
//   drop(props, monitor, component) {
//     if (monitor.didDrop()) {
//       // If you want, you can check whether some nested
//       // target already handled drop
//       return;
//     } 
// 
//     // Obtain the dragged item
//     const taskCard = monitor.getItem();
//     taskCard.onDropTask();
//     
//     // You can also do nothing and return a drop result,
//     // which will be available as monitor.getDropResult()
//     // in the drag source's endDrag() method
//     //return { moved: true };
//   },
// }

/**
 * Specifies which props to inject into your component.
 */
// function dropCollect(connect, monitor) {
//   return {
//     // Call this function inside render()
//     // to let React DnD handle the drag events:
//     connectDropTarget: connect.dropTarget(),
//     // You can ask the monitor about the current drag state:
//     //isOver: monitor.isOver(),
//     // isOverCurrent: monitor.isOver({ shallow: true }),
//     // canDrop: monitor.canDrop(),
//     // itemType: monitor.getItemType()
//   };
// }

class Dashboard extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      // Tasks: [],
      Teams: [],
      ActiveTeamKey: "",
      // PreMoveTasksJson: "",
      // redirectPath: null,
    }
  }

//   addNewTaskToState(newTask) {
//     let newState = this.state;
//     newState.Tasks.push(newTask);
//     this.setState(newState);
//   }
// 
//   Task = {
//     Key: "",
//     Name: "",
//     Type: "",
//     Rank: rankIncrement,
//   }
// 
//   getNewTask(name) {
//     let newTask = this.Task;
//     newTask.Name = name;
//     if (this.state.Tasks && this.state.Tasks.length > 0) {
//       newTask.Rank = rankIncrement + this.state.Tasks[this.state.Tasks.length - 1].Rank;
//     }
//     else {
//       newTask.Rank = rankIncrement;
//     }
//     return newTask;
//   }

  Team = {
    Key: "",
    TeamName: "",
  }

  getNewTeam(name) {
    let newTeam = this.Team;
    newTeam.TeamName = name;
    return newTeam;
  }

 TeamMemberInvitation = {
    Key: "",
    InviteeEmail: "",
    ClientAcceptPath: "",
  }

  getNewTeamMemberInvitation(email) {
    let newInvitation = this.TeamMemberInvitation;
    newInvitation.InviteeEmail = email;
    newInvitation.ClientAcceptPath = "/teamMemberInvitations/acceptance"
    return newInvitation;
  }

  handleActiveTeamKeyChange(key, getTasks) {
    this.setState({ ActiveTeamKey: key });
    // if (getTasks)
    //   this.GetTasks(key);

    API.put("/user/preferences", 
      { ActiveTeamKey: key }
    )
      .then(resp => {
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handleListTeams() {
    API.get("/teams")
      .then(resp => { 
        this.setState({ Teams: resp.data });
      })
      .catch(err => this.setState({ApiError: err}));
  }

  GetUserPreferences() {
    API.get("/user/preferences")
      .then(resp => { 
        this.setState({ ActiveTeamKey: resp.data.ActiveTeamKey });
        //this.GetTasks(resp.data.ActiveTeamKey);
      })
      .catch(err => this.setState({ApiError: err}));
  }

  // GetTasks(teamKey) {
  //   API.get("/tasks", 
  //     { 
  //       params: { team: (teamKey) ? teamKey : undefined }
  //     }
  //   )
  //     .then(resp => { 
  //       this.setState({ Tasks: resp.data });
  //     })
  //     .catch(err => this.setState({ApiError: err}));
  // }

  handleAddTeam(name) {
    if (!name)
      return;
    let newTeam = this.getNewTeam(name);
    API.post("/teams", newTeam)
      .then(resp => {
        let teams = this.state.Teams;
        teams.push(resp.data);
        this.setState({ Teams: teams });
        setTimeout(() => this.handleActiveTeamKeyChange(resp.data.Key, true), 500);
      })
      .catch(err => this.setState({ApiError: err}));
  }

  handleAddTeamMemberInvitation(email) {
    if (!email)
      return;
    let newInvitation = this.getNewTeamMemberInvitation(email);
    API.post("/teamMemberInvitations", newInvitation,
      { 
        params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
      }
    )
      .then(resp => {
        this.setState({ 
          DialogDetails: {
            Open: true,
            Title: "Invitation Sent",
            BodyText: `An invitation has been sent to ${email}`,
          }
        });
      })
      .catch(err => this.setState({ApiError: err}));
  }

//   handleAddTask(name) {
//     if (!name)
//       return;
//     let newTask = this.getNewTask(name);
//     API.post("/tasks", newTask, 
//       { 
//         params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//       }
//     )
//       .then(resp => {
//         let tasks = this.state.Tasks;
//         tasks.push(resp.data);
//         this.setState({ Tasks: tasks })
//       })
//       .catch(err => this.setState({ApiError: err}));
//   }
// 
//   handleDeleteTask(task) {
//     API.delete("/tasks/" + task.Key,
//       { 
//         params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//       }
//     )
//       .then(resp => {
//         let tasks = this.state.Tasks.filter(obj => obj.Key !== task.Key);
//         this.setState({ Tasks: tasks })
//       })
//       .catch(err => this.setState({ApiError: err}));  
//   }
// 
//   handleUpdateTask(task, event) {
//     let tasks = this.state.Tasks;
//     task.Rank = parseFloat(event.target.value);
//     tasks.filter(obj => obj.Key === task.Key)[0].Rank = task.Rank;
//     this.setState({ Tasks: tasks })
// 
//     API.put("/tasks/" + task.Key, task,
//       { 
//         params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//       }
//     )
//       .then(resp => {
//       })
//       .catch(err => this.setState({ApiError: err}));  
//   }
// 
//   handleDropTask(sourceTask) {
//     if (!sourceTask)
//       return;
//     this.reRank(sourceTask);
//   }
// 
//   handleStartMoveTask() {
//     this.setState({ PreMoveTasksJson: JSON.stringify(this.state.Tasks) });
//   }
// 
//   handleAbortMoveTask() {
//     this.setState({ Tasks: JSON.parse(this.state.PreMoveTasksJson) });
//   }
// 
//   handleMoveTask(sourceTask, targetTask) {
//     if (!sourceTask)
//       return;
//     // console.log("Source Task: ", sourceTask.Name);
//     if (targetTask) {
//       // console.log("Target Task: ", targetTask.Name);
//       if (sourceTask.Rank === targetTask.Rank)
//         return;
//     }
// 
//     let tasks = this.state.Tasks;
//     let sourceIndex = tasks.indexOf(sourceTask);
//     let targetIndex = tasks.indexOf(targetTask);
//     if (sourceIndex === targetIndex)
//       return;
// 
//     //console.log("Index of source: ", sourceIndex, " Index of target: ", targetIndex);
//     if (sourceIndex === null || targetIndex === null)
//       return;
// 
//     tasks.splice(targetIndex, 1);
//     tasks.splice(sourceIndex, 0, targetTask);
//     this.setState({ Tasks: tasks });
// 	}
// 
//   reRank(sourceTask) {
//     let tasks = this.state.Tasks;
//     let sourceIndex = tasks.map(t => t.Key).indexOf(sourceTask.Key);
//     let startIndex = sourceIndex;
//     let endIndex = sourceIndex;
// 
//     let increment = rankIncrement;
//     let lastIncrement = -1;
//     while (true) {
//       // This protects against an endless loop
//       if (lastIncrement === increment)
//         break;
// 
//       if (startIndex > 0)
//         startIndex--;
//       if (endIndex < tasks.length - 1)
//         endIndex++;
//       if (startIndex === endIndex)
//         return;
//       // console.log("index start ", startIndex, "end ", endIndex);
// 
//       let startRank = (startIndex === 0) ? 0 : tasks[startIndex].Rank;
//       let endRank = tasks[endIndex].Rank;
//       // console.log("rank start ", startRank, "end ", endRank);
// 
//       lastIncrement = increment;
//       increment = Math.abs((endRank - startRank) / 3);
// 
//       // console.log("increment ", increment);
// 
//       // If increment is too small, we need to cycle, which will incorporate outside items
//       if (increment >= 1) {
//         let curRank = startRank;
//         for (let i = startIndex; i <= endIndex; i++) {
//           curRank += increment;
//           tasks[i].Rank = curRank;
//         }
//         break;
//       }
//     }
//     
//     this.setState({ tasks: tasks});
// 
//     let preMoveTasks = JSON.parse(this.state.PreMoveTasksJson);
//     for (let i = startIndex; i <= endIndex; i++) {
//       console.log("original: ", preMoveTasks[i].Rank, " new: ", tasks[i].Rank);
//       if (tasks[i].Rank === preMoveTasks[i].Rank)
//         continue;
//       API.put("/tasks/" + tasks[i].Key, tasks[i],
//         { 
//           params: { team: (this.state.ActiveTeamKey) ? this.state.ActiveTeamKey : undefined }
//         }
//       )
//         .then(resp => {
//         })
//         .catch(err => console.log(err));
//     }
//   }

  componentDidMount() {
    this.handleListTeams();

    // If the team was passed in via querystring, change the active team to the passed value
    const values = queryString.parse(this.props.location.search);
    if (values.team) {
      this.handleActiveTeamKeyChange(values.team, true);
    }
    else {
      this.GetUserPreferences();
    }
  }
  
  render() {
    const {
      ApiError,
      Alert,
    } = this.state;
    // const { 
    //   connectDropTarget,
    // } = this.props;

    // if (this.state.redirectPath) {
    //   return (
    //     <Redirect to={this.state.redirectPath} />
    //   );
    // }

    //console.log(this.state.Tasks);
    // let taskCards = [];
    // if (this.state.Tasks.length > 0) {
    //   taskCards = this.state.Tasks.map(task =>
    //       <Grid item key={task.Key}>
    //         <TaskCard Task={task}
    //           onDeleteTask={() => this.handleDeleteTask(task)}
    //           onUpdateTask={e => this.handleUpdateTask(task, e)}
    //           onStartMoveTask={() => this.handleStartMoveTask()}
    //           onAbortMoveTask={() => this.handleAbortMoveTask()}
    //           onMoveTask={taskTarget => this.handleMoveTask(task, taskTarget)}
    //           onDropTask={() => this.handleDropTask(task)}
    //         >
    //         </TaskCard>
    //       </Grid>
    //   );
    // }

    // return connectDropTarget(
    return (
      <div>
        <UiCore title="Dashboard"
          apiError={ApiError}
          alert={Alert}
        />

        {/* <AddTeam */}
        {/*   onAddTeam={name => this.handleAddTeam(name)} */}
        {/* /> */}
        {/* <TeamSelector teams={this.state.Teams} */}
        {/*   activeTeamKey={this.state.ActiveTeamKey} */}
        {/*   onActiveTeamKeyChange={key => this.handleActiveTeamKeyChange(key, true)} /> */}
        {/* <Invite */}
        {/*   onInvite={email => this.handleAddTeamMemberInvitation(email)} */}
        {/* /> */}
        {/* <AddTask */}
        {/*   onAddTask={name => this.handleAddTask(name)} */}
        {/*  /> */}
        {/* <div style={{padding: 24}}> */}
        {/*   <Grid container spacing={24}> */}
        {/*   {taskCards} */}
        {/*   </Grid> */}
        {/* </div> */}
      </div>
    );
  }
}

// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default DropTarget('TaskCard', gridTarget, dropCollect)(Dashboard);
export default Dashboard;