import React from 'react';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import GestureIcon from '@material-ui/icons/Gesture';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

const TaskType = {
	Trigger_Manual: {
		Id: "Trigger_Manual",
		IsTrigger: true,
		Name: "Manual Trigger",
		Icon: (<PersonIcon />),
		VertexStyle: "terminal",
		Width: 120,
		Height: 60,
	},

	Approval: {
		Id: "Approval",
		Name: "Approval",
		Icon: (<ThumbUpIcon />),
		VertexStyle: "decision",
		Width: 160,
		Height: 80,
	},
	Email: {
		Id: "Email",
		Name: "E-mail",
		Icon: (<EmailIcon />),
		VertexStyle: "task",
		Width: 120,
		Height: 60,
	},
	Kanban: {
		Id: "Kanban",
		Name: "Kanban",
		Icon: (<ViewWeekIcon />),
		VertexStyle: "task",
		Width: 120,
		Height: 60,
	},
	Manual: {
		Id: "Manual",
		Name: "Manual Task",
		Icon: (<PersonIcon />),
		VertexStyle: "task",
		Width: 120,
		Height: 60,
	},
	Signature: {
		Id: "Signature",
		Name: "Signature",
		Icon: (<GestureIcon />),
		VertexStyle: "task",
		Width: 120,
		Height: 60,
	},
};

export default TaskType;