import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect  } from 'react-router-dom';

import Login from './Screens/Login';
import NotFound404 from './Screens/NotFound404';
import Dashboard from './Screens/Dashboard';
import TeamMemberInvitationAccept from './Screens/TeamMemberInvitationAccept';
import Logout from './Screens/Logout';

import AdminProcesses from './Admin/Screens/Processes'
import AdminProcess from './Admin/Screens/Process'

import { withCookies } from 'react-cookie';

import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import mxGraphGridAreaEditor from './Admin/mxgraph-sandbox/mxgraph.js'

import './App.css';

const Authentication = {
  isAuthenticated(cookies) {
    let c = cookies.get("isAuthenticated")
    return c !== undefined && c === "true";
  }
}

const ProtectedRoute = withCookies(({ component: Component, ...rest }) =>
  <Route {...rest} render={(props) => (
    Authentication.isAuthenticated(rest.cookies) === true
      ? <Component {...props} />
      : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
  )} />
)

const theme = createMuiTheme({
  palette: {
    primary: { main: "#009688" },
    secondary: { main: "#03A9F4", contrastText: "#FFFFFF" },
  },
  // status: {
  //   danger: 'orange',
  // },
  typography: {
    // fontFamily: [
    //   "Roboto",
    //   "-apple-system",
    //   "BlinkMacSystemFont",
    //   "Segoe UI",
    //   "Arial",
    //   "sans-serif"
    // ].join(","),
    useNextVariants: true
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoute exact path={"/"} component={AdminProcesses} />
            <Route exact path={"/login"} component={Login} />
            <Route path={"/logout"} component={Logout} />

            <ProtectedRoute path={"/dashboard"} component={Dashboard} />
            <ProtectedRoute path={"/admin/processes"} component={AdminProcesses} />
            <ProtectedRoute path={"/admin/process/:processKey"} component={AdminProcess} />

            <ProtectedRoute path={"/admin/graph"} component={mxGraphGridAreaEditor} />

            <ProtectedRoute path={"/teamMemberInvitations/acceptance"} component={TeamMemberInvitationAccept} />
            
            <Route component={NotFound404} />
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    );
  }
}

export default DragDropContext(HTML5Backend)(App);
