import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField: {
    marginTop: theme.spacing.unit,
  },
  warning: {
    color: "#E53935",
  }
});

class MultiUseDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    }

    this.textInput1Ref = React.createRef();
  }

  handleClose(callback) {
    this.setState({ open: false });
    if (typeof callback !== "undefined" && callback) {
      let textInput1 = (this.textInput1Ref) && this.textInput1Ref.value;
      callback(textInput1);
    }
  }

  componentDidUpdate(prevProps) {
    if (typeof this.props.open !== "undefined"
      && prevProps.open !== this.props.open) {
      this.setState({open: true});
    }
  }

  render() {
    const {
      open,
    } = this.state;
    const { 
      classes,
      title,
      bodyText,
      bodyClassName,
      isConfirmation,
      requireTextInput1,
      textInput1Label,
      textInput1PlaceHolder,
      textInput1DefaultValue,
      closeCallback,
      cancelCallback,
      confirmLabel,
      confirmCallback,
    } = this.props;

    let dialogActions = null;
    if (isConfirmation) {
      dialogActions = (
        <DialogActions>
          <Button onClick={() => this.handleClose(cancelCallback)} color="primary">
            CANCEL
          </Button>
          <Button onClick={() => this.handleClose(confirmCallback)} color="primary">
            {confirmLabel || "CONFIRM"}
          </Button>
        </DialogActions>
      );
    } 
    else {
      dialogActions = (
        <DialogActions>
          <Button onClick={() => this.handleClose(closeCallback)} color="primary">
            CLOSE
          </Button>
        </DialogActions>
      );
    }

    let dialogContentExtras = null;
    if (requireTextInput1) {
      dialogContentExtras = (
        <TextField
          inputRef={input => (this.textInput1Ref = input)}
          className={classes.textField}
          fullWidth
          autoFocus
          label={textInput1Label}
          placeholder={textInput1PlaceHolder}
          defaultValue={textInput1DefaultValue}
          onKeyPress={(e) => {if (e.key === "Enter") this.handleClose(confirmCallback);}}
        />
      );
    }

    return (
      <Dialog
        open={open}
        onClose={() => this.handleClose(closeCallback)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          <span>{title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description" className={classes[bodyClassName]}>
            {bodyText}
          </DialogContentText>
          {dialogContentExtras}
        </DialogContent>
        {dialogActions}
      </Dialog>
    );
  }
}

MultiUseDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(MultiUseDialog);