import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import API from '../Util/api';

class Logout extends Component {
   constructor(props) {
     super(props);

     this.state = {
       redirectPath: null,
     }
   }

  logout() {
    API.get("/user/logout")
        .then(resp => {
          if (resp.status === 200) {
            this.setState({ redirectPath: "/" });
          }
        })
        .catch(err => console.log(err));
  }

  componentDidMount() {
    this.logout();
  }

  render() {
    if (this.state.redirectPath) {
      return (
        <Redirect to={this.state.redirectPath} />
      );
    }

    return (
      <div></div>
    );
  }
}

export default Logout;
