import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import UiCore from '../Components/UiCore';

import API from '../Util/api';

import queryString from 'query-string';

class TeamMemberInvitationAccept extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      redirectPath: null,
    }
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.invitation) {
      	API.get("/teamMemberInvitations/acceptance/" + values.invitation)
		  .then(resp => {
		    this.setState({ redirectPath: "/tasks" })
		  })
		  .catch(err => this.setState({ApiError:err}));
    }
    else {
      this.setState({ redirectPath: "/tasks" });
    }
  }
  
  render() {
    const {
      ApiError,
      Alert, 
    } = this.state;
    if (this.state.redirectPath) {
      return (
        <Redirect to={this.state.redirectPath} />
      );
    }

    return (
      <div>
        <UiCore title="Team-Invitation Acceptance"
          apiError={ApiError}
          alert={Alert}
        />
      </div>
    );
  }
}

export default TeamMemberInvitationAccept;
