import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { DragSource } from 'react-dnd';

/**
 * Implements the drag source contract.
 */
const listItemSource = {
  beginDrag(props) {
    // props.onStartMove();
    return {
      TaskType: props.TaskType,
      onDrop: props.onDrop,
    };
  },
  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      // props.onAbortMove();
    }
    // props.onDrop(monitor.getDropResult(), props.TaskType);
  }
};

/**
 * Specifies the props to inject into your component.
 */
function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
}

class DraggableListItem extends Component {
  render() {
     const { 
       // isDragging,
       connectDragSource,
       Text,
       Icon,
    } = this.props;

    return (
        <ListItem button disableRipple ref={instance => connectDragSource(ReactDOM.findDOMNode(instance))}>
          <ListItemIcon>
            {Icon}
          </ListItemIcon>
          <ListItemText primary={Text} />
        </ListItem>
    );
  }
}

export default DragSource('DraggableListItem', listItemSource, dragCollect)(DraggableListItem)  